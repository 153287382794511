:root {
  --bg: white;
  --fg: black;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg: black;
    --fg: white;
  }
}

body {
  background-color: var(--bg);
}

/* Root setup */
#wdleRoot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  min-height: 100vh;
}

/* Hack for iOS Safari, which otherwise occludes some of the display with the footer */
@supports (-webkit-touch-callout: none) {
  #wdleRoot {
    min-height: -webkit-fill-available;
  }
}

/* Title base styles */
#wdleTitle {
  height: fit-content;
  text-align: center;
  color: var(--fg);
}

/* Title text children need no margin, otherwise a bunch of space is wasted */
#wdleTitle > h1 {
  font-size: 150%;
  margin-top: 0;
  margin-bottom: 0;
}

#wdleTitle > p {
  font-size: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

/* If the screen is over 900px, show full size title */
@media screen and (min-height: 900px) {
  #wdleTitle > h1 {
    font-size: 500%;
  }

  #wdleTitle > p {
    font-size: 30px;
  }
}

/* Base grid styles */
.wdleGameRoot {
  background-color: var(--bg);

  display: grid;
  align-items: center;
  justify-content: center;

  width: 50%;
}

/* Set opacity of everything behind completion modal to 70% */
.completed {
  opacity: 50%;
}

/* Modal styling */
.wdleRootCompletionModal {
  position: absolute;

  background-color: var(--bg);
  border: 1px solid var(--fg);
  color: var(--fg);

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  width: fit-content;
  max-width: 80%;
  height: fit-content;
  padding: 10px;
  z-index: 1001;
}

.wdleRootCompletionModal > h1 {
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Base styling for fake buttons */
button {
  color: white;
  border: 1px solid var(--fg);
  background-color: grey;
  text-align: center;
  padding: 3px;
  margin: 3px;
  width: 45%;
  height: 2rem;
  display: inline-block;
}

/* Button hover styling */
button:hover {
  cursor: pointer;
  opacity: 75%;
}

/* When the modal is for success, color header to green */
h1.wdleRootSuccess {
  color: green;
}

img.yippee {
  width: 20rem;
  height: auto;
  align-self: center;
}

/* Otherwise, color header red */
h1.wdleRootFailure {
  color: red;
}

/* Game grid styles */
.wdleGridRoot {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  gap: 8px;
}

/* Game row styles */
.wdleRow {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
}

/* Base guess stles */
.baseGuess {
  background-color: rgb(102, 102, 102);
  color: white;
  border: 1px solid var(--bg);

  font-weight: bolder;
  font-size: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: calc(12.5vw);
  width: calc(12.5vw);
}

/* If the screen is >= 500px width, lock the grid cells to 75x75px */
@media screen and (min-width: 500px) {
  .baseGuess {
    height: 75px;
    width: 75px;
  }
}

/* Color for active row */
.rowActive > .baseGuess {
  background-color: #8d8d8d;
}

/* Common style for successful guesses */
.wdleGuessGood {
  background-color: green;
}

/* Common style for unsuccessful guesses */
.wdleGuessBad {
  background-color: rgb(85, 85, 85);
}

/* Common style for "close" guesses */
.wdleGuessClose {
  background-color: orange;
}
