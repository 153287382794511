#keyboardRoot {
  color: white;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  column-gap: 5px;

  width: 50%;
  height: fit-content;
}

.keyboardKey {
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 4vw;
  height: 7.5vw;
  padding: 2.2vw;

  margin-top: 5px;
  margin-bottom: 5px;

  min-width: fit-content;
}

.keyboardKey:active {
  opacity: 70%;
}

.keyRow .specialKey {
  width: 6vw;
}

@media screen and (min-width: 600px) {
  .keyboardKey {
    width: 50px;
    height: 50px;
    padding: 2px;
  }

  .keyRow .specialKey {
    width: 80px;
  }
}

.unusedKey {
  background-color: grey;
}

.keyRow {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  column-gap: 5px;
}
